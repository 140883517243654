import { methodService, apiService } from "./apiService";

export const URL = {
  BASE_MEMBER: "/accounts/lionsBefrienders",
};

export function apiMemberLions() {
  return apiService(
    URL.BASE_MEMBER,
    methodService.GET,
    null,
    null
  );
}

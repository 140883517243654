import React, {useEffect} from 'react';
import {Header} from '../../Donation/Components/Header/index';
import {Footer} from '../../Donation/Components/Footer/index';
import {ThanksMessages} from '../../ThankYou/Components/ThanksMessages/index';
import {animateScroll as scroll} from 'react-scroll';

export function ThankYou() {
  useEffect(() => {
    scroll.scrollToTop();
  }, []);

  return (
    <>
      <div>
        <Header />
        <img
          src="/Media/lb-donate-thankyou.png"
          width={'100%'}
          style={{
            maxHeight: 680,
          }}
        />
        <ThanksMessages />
        <Footer />
      </div>
    </>
  );
}

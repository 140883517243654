import React, { useEffect, useState } from "react";
import { Container, Card, Button, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./FormReview.css";

export function FormReview() {
  const history = useHistory();
  const [dataForm, setDataForm] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (history?.location?.data !== undefined) {
      setDataForm(history?.location?.data);
    } else {
      const dataStorage = JSON.parse(localStorage.getItem("dataForm"));
      setDataForm(dataStorage);
    }
    window.scrollTo(0, 50);
  }, []);

  function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }

  function onDonation() {
    setIsLoading(true);
    let payload = {
      frequency: dataForm.frequency === "onetime" ? "One-time" : "Monthly",
      amount:
        dataForm.amount !== "Other"
          ? parseInt(dataForm.amount)
          : parseInt(dataForm.inputAmount),
      donorType:
        dataForm.donationType === "ind" ? "Individual" : "Organisation",

      fullname: dataForm.fullname,

      idType: dataForm.idType,
      idNumber: dataForm.idNumber,

      email: dataForm.email,
    };
    if (dataForm.donationType === "org") {
      payload.orgName = dataForm.orgName;
    }

    history.push({
      pathname: "/thank-you",
      data: dataForm,
    });
  }

  return (
    <section style={{ margin: "0px 0px 50px", padding: "40px 80px" }}>
      <Card
        className="card-body"
        style={{
          paddingBottom: "40px",
          border: "2px solid rgb(221, 221, 221)",
        }}
      >
        <Row>
          <Col xl={12}>
            <h3
              style={{
                fontSize: "30px",
                fontWeight: "bold",
                margin: "0px 0px 25px",
                color: "#1d1e23",
                fontFamily: "Montserrat,sans-serif",
              }}
              className="title-donation"
            >
              Review Your Donation Details
            </h3>
          </Col>
        </Row>
        <Container
          className="info-review-donation"
          style={{ padding: "0px", marginTop: "20px" }}
        >
          <Row>
            <Col xl={5}>
              <h4
                style={{
                  margin: "18px 0px",
                  display: "flex",
                  justifyContent: "flex-end",
                  color: "#666",
                }}
                className="info-review-form"
              >
                Please review your donation details below :
              </h4>
            </Col>
          </Row>
          <Row>
            <Col
              xl={5}
              xs={5}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <h4 className="info-review-form">Donation Amount :</h4>
            </Col>
            <Col xl={7} xs={7}>
              <h4 className="info-review-form">
                $
                {dataForm?.amount !== "Other"
                  ? dataForm?.amount
                  : dataForm?.inputAmount}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col
              xl={5}
              xs={5}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <h4 className="info-review-form">Frequency :</h4>
            </Col>
            <Col xl={7} xs={7}>
              <h4 className="info-review-form">{`${capitalizeFirstLetter(
                dataForm?.frequency
              )} donation`}</h4>
            </Col>
          </Row>
          <Row>
            <Col
              xl={5}
              xs={5}
              style={{ display: "flex", justifyContent: "flex-end" }}
              className="info-review-form"
            >
              <h4>Fullname :</h4>
            </Col>
            <Col xl={7} xs={7}>
              <h4 className="info-review-form">{dataForm?.fullname}</h4>
            </Col>
          </Row>

          {dataForm?.donorType === "Organisation" && (
            <Row>
              <Col
                xl={5}
                xs={5}
                style={{
                  display: dataForm?.donationType === "ind" ? "none" : "flex",
                  justifyContent: "flex-end",
                }}
              >
                <h4 className="info-review-form">Organisation :</h4>
              </Col>
              <Col xl={7} xs={7}>
                <h4 className="info-review-form">{dataForm?.orgName}</h4>
              </Col>
            </Row>
          )}

          {dataForm?.isTax && (
            <Row>
              <Col
                xl={5}
                xs={5}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <h4 className="info-review-form">
                  {dataForm?.donationType === "ind" ? "NRIC/FIN :" : "UEN :"}
                </h4>
              </Col>
              <Col xl={7} xs={7}>
                <h4 className="info-review-form">{dataForm?.idNumber}</h4>
              </Col>
            </Row>
          )}
          <Row>
            <Col
              xl={5}
              xs={5}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <h4 className="info-review-form">Email :</h4>
            </Col>
            <Col xl={7} xs={7}>
              <h4 className="info-review-form">{dataForm?.email}</h4>
            </Col>
          </Row>

          <div>
            <h6
              className="info-review-form"
              style={{
                fontWeight: "bold",
                marginTop: "50px",
                fontFamily: "Roboto",
              }}
            >
              If your donation details are correct, please read the Terms and
              Conditions below before proceeding to the next step. If you wish
              to make changes, please click here to return to the donation form
            </h6>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <Button
              style={{
                width: "200px",
                height: "50px",
                borderRadius: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "30px",
                backgroundColor: "#12307a",
              }}
              onClick={onDonation}
            >
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  fontFamily: "Montserrat,sans-serif",
                }}
              >
                DONATE SECURELY WITH CREDIT CARD
              </span>
            </Button>
          </div>
        </Container>
      </Card>
      <div style={{ marginTop: "75px" }}>
        <div>
          <h5 className="info-review-form" style={{ fontWeight: "bold" }}>
            Terms & Conditions
          </h5>
        </div>
        <div style={{ marginTop: "25px" }}>
          <h5 className="info-review-form">
            Please note that donations are non-refundable.
          </h5>
        </div>
      </div>
    </section>
  );
}

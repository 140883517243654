import React from 'react';
import { Modal , Button } from 'react-bootstrap'

export function ModalError({show , close , error, message}) {
    return (
        <Modal
            size="xl"
            show={show}
            centered
        >
            <Modal.Header closeButton={false}>
                <Modal.Title>{error?.name}</Modal.Title>
                <Button variant="secondary" onClick={close} >Close</Button>
            </Modal.Header>
            <Modal.Body>
                <h4>{message}</h4>
                <p>{error?.stack}</p>
            </Modal.Body>
        </Modal>
    )
}
import React from 'react';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import './index.css';

export function TitleImage({title}) {
  return (
    <>
      {' '}
      <div
        className="container-title-image"
        style={{
          textAlign: 'center',
        }}>
        {' '}
        <div className="bg-overlay">
          <LazyLoadImage src={'/Media/img-header/lb-banner.png'} className="bg-nav-overlay" />
        </div>
        <div className="message">
          <h1
            style={{
              color: '#ffffff',
              fontWeight: 'bold',
              fontFamily: 'Montserrat,sans-serif',
            }}>
            {title}
          </h1>
        </div>
      </div>
    </>
  );
}

import React from 'react';
import {HiMinusSm} from 'react-icons/hi';
import {FaFacebook, FaInstagram, FaLinkedinIn} from 'react-icons/fa';

import './index.css';

export function Footer() {
  return (
    <div
      className="container-messages"
      style={{
        width: '100%',
        minHeight: '530px',
        backgroundColor: '#011627',
        left: '0',
        bottom: '0',
      }}>
      <div className="content">
        <div className="content-footer">
          <div className="about-footer">
            <h1
              className="title-content"
              style={{
                color: '#ffffff',
                fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                fontSize: '20px',
              }}>
              About
            </h1>
            <p
              style={{
                color: '#ffffff',
                fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                fontSize: '16 px',
              }}>
              Lions Befrienders Service Association (Singapore)
            </p>
            <p
              style={{
                color: '#ffffffbf',
                fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                fontSize: '16 px',
              }}>
              A Social Service Agency formed in 1995 by the Lions Clubs of Singapore and a Lions
              District Project. Our mission is to provide friendship and care for seniors to age in
              place with community participation, enabling them to enjoy meaningful enriching lives.
            </p>
            <div
              style={{
                display: 'flex',
              }}>
              <a href="https://www.facebook.com/lionsbefrienders/" style={{marginRight: 10}}>
                <FaFacebook size={35} color={'#838A90'} />
              </a>
              <a href="https://www.instagram.com/lionsbefrienders/" style={{marginRight: 10}}>
                <FaInstagram size={35} color={'#838A90'} />
              </a>
              <a href="https://www.linkedin.com/company/lions-befrienders-singapore">
                <FaLinkedinIn size={35} color={'#838A90'} />
              </a>
            </div>
          </div>
          <div className="services-footer">
            <h1
              className="title-content"
              style={{
                color: '#ffffff',
                fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                fontSize: '20px',
              }}>
              Services
            </h1>
            <ul style={{margin: '0', padding: '0'}}>
              <li
                style={{
                  color: '#ffffffbf',
                  fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                  fontSize: '16px',
                  marginBottom: '10px',
                }}>
                <a
                  href="https://www.lionsbefrienders.org.sg/services-senior-activity-centre-2/"
                  style={{
                    textDecorationLine: 'none',
                    color: 'inherit',
                  }}>
                  <HiMinusSm /> Active Ageing Centre
                </a>
              </li>
              <li
                style={{
                  color: '#ffffffbf',
                  fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                  fontSize: '16px',
                  marginBottom: '10px',
                }}>
                <a
                  href="https://www.lionsbefrienders.org.sg/services-befriending/"
                  style={{
                    textDecorationLine: 'none',
                    color: 'inherit',
                  }}>
                  <HiMinusSm /> Befriending{' '}
                </a>
              </li>
              <li
                style={{
                  color: '#ffffffbf',
                  fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                  fontSize: '16px',
                  marginBottom: '10px',
                }}>
                <a
                  href="https://www.lionsbefrienders.org.sg/services-home-personal-care/"
                  style={{
                    textDecorationLine: 'none',
                    color: 'inherit',
                  }}>
                  <HiMinusSm /> Home Personal Care
                </a>
              </li>
              <li
                style={{
                  color: '#ffffffbf',
                  fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                  fontSize: '16px',
                  marginBottom: '10px',
                }}>
                <a
                  href="https://www.lionsbefrienders.org.sg/services-sac-cluster-support/"
                  style={{
                    textDecorationLine: 'none',
                    color: 'inherit',
                  }}>
                  <HiMinusSm /> SAC Cluster Support
                </a>
              </li>
              <li
                style={{
                  color: '#ffffffbf',
                  fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                  fontSize: '16px',
                  marginBottom: '10px',
                }}>
                <a
                  href="https://www.lionsbefrienders.org.sg/services-senior-group-home/"
                  style={{
                    textDecorationLine: 'none',
                    color: 'inherit',
                  }}>
                  <HiMinusSm /> Senior Group Home
                </a>
              </li>
            </ul>
          </div>
          <div className="Contact-footer">
            <h1
              className="title-content"
              style={{
                color: '#ffffff',
                fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                fontSize: '20px',
              }}>
              Contact
            </h1>
            <div
              className="contact-list"
              style={{
                color: '#ffffffbf',
                fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                fontSize: '16px',
              }}>
              <p>HQ: 130 Bukit Merah View, #01-358, Singapore, 150130</p>
              <p>1800 375 8600</p>
              <p>Monday - Friday: 9am to 6pm Saturday / Sunday: Closed</p>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-footer">
        <hr className="border-footer" />
        <div
          className="copyright"
          style={{
            color: '#ffffffbf',
            fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
            fontSize: '16px',
          }}>
          <p>Copyright © 2021 Lions Befrienders</p>
          <a
            href="https://www.lionsbefrienders.org.sg/privacy-policy/"
            style={{
              textDecorationLine: 'none',
              color: 'inherit',
            }}>
            <p>Privacy Policy</p>
          </a>
        </div>
      </div>
    </div>
  );
}

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {DonationPage} from '../src/App/Modules/Donation/Pages/DonationPage';
import {DonationReview} from '../src/App/Modules/DonationReview/Pages/DonationReview';
import {ThankYou} from '../src/App/Modules/ThankYou/Pages/ThankYou';
import {Error404} from '../src/App/Modules/Error/404';
function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path={process.env.REACT_APP_BUILD_TYPE === 'development' ? '/donate' : '/'}>
            <DonationPage />
          </Route>
          <Route path="/donation-review">
            <DonationReview />
          </Route>
          <Route path="/thank-you">
            <ThankYou />
          </Route>
          <Route component={Error404} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;

import {methodService, apiService} from './apiService';

export const URL = {
  BASE_URL: '/donations',
  BASE_PROGRAMME: '/programmes',
};

export function apiSendDonation(data) {
  return apiService(URL.BASE_URL, methodService.POST, data, null);
}

export function apiGetProgramme(programmeId) {
  return apiService(URL.BASE_PROGRAMME + `/${programmeId}`, methodService.GET, null, null);
}

export function apiGetDonationDetail(donationNumber) {
  return apiService(URL.BASE_URL + `/${donationNumber}`, methodService.GET, null, null);
}

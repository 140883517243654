import React from 'react';
import {Container} from 'react-bootstrap';
import './index.css';

export function DonationInfo() {
  return (
    <Container className="container-messages" style={{marginTop: '30px', marginBottom: '30px'}}>
      {' '}
      <div
        className="title-donation-messages"
        style={{
          fontFamily: 'Montserrat,sans-serif',
          fontSize: '18px',
          fontWeight: 'bolder',
        }}>
        Thank you for your donation, every dollar counts, as your donation will help to support our
        programmes and services for our seniors!
      </div>
      <div
        className="messages"
        style={{
          fontFamily: 'Roboto,Helvetica, sans-serif',
          fontWeight: '18px',
          paddingRight: '50px',
          marginTop: '25px',
        }}>
        <ol>
          <li>
            E-tax deduction receipt of 250 % will be issued for all donations, if you have
            providedus with your email address and NRIC/FIN/UEN.
          </li>
          <li>
            If you have questions, please call 1800 375 8600 or email{' '}
            <a
              href="mailto:donate@lb.org.sg"
              style={{color: '#009688', textDecorationLine: 'none', cursor: 'pointer'}}>
              donate@lb.org.sg
            </a>
          </li>
        </ol>
      </div>
    </Container>
  );
}

import React from "react";
import { Header } from "../Components/Header/index";
import { TitleImage } from "../Components/TitleImage/index";
import { DonationForm } from "../Components/FormDonate/index";
import { DonationInfo } from "../Components/DonationInfo/index";
import { Footer } from "../Components/Footer/index";

export function DonationPage() {
  return (
    <>
      <div>
        <Header />
        <TitleImage title={"DONATE"} />
        <DonationForm />
        <DonationInfo />
        <Footer />
      </div>
    </>
  );
}

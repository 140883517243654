import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import queryString from 'query-string';
import './index.css';
import {apiGetDonationDetail} from '../../../../../dataSource/donationRepo';

export function ThanksMessages() {
  const [idDonation, setIdDonation] = useState('');
  const history = useHistory();
  const [dataForm, setDataForm] = useState(null);
  const [donationDetail, setDonationDetail] = useState(null);
  const [status, setStatus] = useState('success');
  useEffect(() => {
    if (history?.location?.data !== undefined) {
      setDataForm(history?.location?.data);
    } else {
      const dataStorage = JSON.parse(localStorage.getItem('dataForm'));
      setDataForm(dataStorage);
    }

    const lastSlashIndex = history.location.pathname.lastIndexOf('/');
    if (
      lastSlashIndex !== -1 &&
      lastSlashIndex < history.location.pathname.length - 1 &&
      lastSlashIndex > 0
    ) {
      const donationNumber = history.location.pathname.substring(lastSlashIndex + 1);
      setIdDonation(donationNumber);
    } else {
      setIdDonation(history?.location?.data?.donation?.number);
    }

    if (!!history.location.search) {
      console.log(queryString.parse(history.location.search));
      let search = queryString.parse(history.location.search);
      console.log(search.donationNumber);
      setIdDonation(search.donationNumber);
      setDataForm({
        ...dataForm,
        amount: search.amount,
        frequency: /^recurring$/i.test(search?.type) ? 0 : 1,
      });
      setStatus(search.status);
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (idDonation) {
      apiGetDonationDetail(idDonation).then(res => setDonationDetail(res.data));
    }
  }, [idDonation]);

  return (
    <>
      <div className="thanks-message" style={{padding: '30px', textAlign: 'center'}}>
        <div className="donation-success-info">
          <p
            style={{
              fontFamily: 'Montserrat,sans-serif',
              fontWeight: 'bolder',
              fontSize: '18px',
              marginBottom: '20px',
            }}>
            Your donation is{' '}
            {status === 'success' ? (
              <b className="text-success">Successful</b>
            ) : (
              <b className="text-danger">Failed</b>
            )}
          </p>
          <p
            style={{
              fontFamily: 'Roboto',

              fontSize: '16px',
            }}>
            <b>Donation Details</b>
          </p>
          <p
            style={{
              fontFamily: 'Roboto',

              fontSize: '16px',
            }}>
            Amount donated: $
            {donationDetail
              ? donationDetail?.Donation_Amount__c
              : dataForm?.amount === 'Other'
              ? dataForm?.inputAmount
              : dataForm?.amount}
            <br />
            Donation type: {dataForm?.frequency === 0 ? 'Monthly Donation' : 'Onetime Donation'}
            <br />
            Donation number: {idDonation}
            <br />
          </p>
          <p
            style={{
              fontFamily: 'Roboto',

              fontSize: '16px',
            }}>
            An email with your donation information has been sent to you. Please call 1800 375 8600
            or email <a href="mailto:donate@lb.org.sg">donate@lb.org.sg</a> if you do not receive
            it.
          </p>
        </div>
      </div>
    </>
  );
}

import React from 'react';
import {useHistory} from 'react-router-dom';
import {Nav, Navbar} from 'react-bootstrap';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import './index.css';

export function Header() {
  const history = useHistory();

  return (
    <Navbar
      expand="xl"
      variant="dark"
      className="Navbar-container"
      style={{
        padding: '0px',
      }}>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            top: '10px',
            left: '2%',
            fontWeight: 'bold',
            fontSize: '18px',
            width: '95%',
            textDecoration: 'none',
            position: 'absolute',
            zIndex: 1000,
          }}
          className="me-auto navbar">
          <LazyLoadImage src="/Media/img-header/LB-Logo-White.png" className="nav-logo" />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

import React from 'react';
import {Modal, Button, Row, Col, Form, Container} from 'react-bootstrap';
import './ModalAnnualReport.css';

export function ModalAnnualReport({show, onHide, onConfirm, onCancel}) {
  function handleSubmit(event) {
    event.preventDefault();
    onConfirm();
    onHide();
  }

  function handleCancel() {
    onCancel();
    onHide();
  }

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
      backdrop="static"
      dialogClassName="modal-annual-report">
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Container
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}>
            <Form.Label>
              Your name will be included in our Virtual Kindness Garden to recognise your
              contribution.
            </Form.Label>
            <Form.Label>Please click Yes to Proceed or No to decline.</Form.Label>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'col-lg-12 d-flex justify-content-center'}>
            <Col md="auto">
              <Button
                type="submit"
                style={{minWidth: '10rem', backgroundColor: '#66bb6a', borderColor: '#66bb6a'}}>
                Yes
              </Button>
            </Col>
            <Col md="auto">
              <Button onClick={handleCancel} style={{minWidth: '10rem'}} variant="secondary">
                No
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

import React from 'react';
import {Header} from '../../Donation/Components/Header/index';
import {Footer} from '../../Donation/Components/Footer/index';
import {FormReview} from '../../DonationReview/Components/FormReview/FormReview';

export function DonationReview() {
  return (
    <>
      <div>
        <Header title={'Donation Review'} />
        <FormReview />
        <Footer />
      </div>
    </>
  );
}
